<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'policy-summary-panel'"
      >
        <i class="fas fa-list-alt fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Policy Summary</span>

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse id="policy-summary-panel" v-model="isExpanded">
        <div v-if="appData">
          <!-- ROW 1 -->
          <div class="form-group row mb-4 mt-4">
            <label class="col-2 text-right">Policy Number</label>
            <div class="col-2 font-weight-bold">
              <a :href="policyCrmLink" target="_blank">
                {{ appData.policy.K_PolicyNumber }}
              </a>
            </div>

            <label class="col-2 text-right">Situation</label>
            <div class="col-2 font-weight-bold">
              <span v-if="appData.asset.K_Address">
                <a target="_blank" :href="assetCrmLink">
                  {{ appData.asset.K_Address }}
                </a>
                <a target="_blank" :href="assetAddressMapLink">
                  <i class="ml-2 fas fa-map-marker-alt fa-lg text-danger"></i>
                </a>
              </span>
              <span v-else>
                <a target="_blank" :href="assetCrmLink">
                  {{ appData.asset.Name }}
                </a>
              </span>
            </div>

            <label class="col-2 text-right">Plan Number</label>
            <div class="col-2 font-weight-bold">
              <a target="_blank" :href="assetCrmLink">
                {{ appData.asset.K_PlanNumber }}
              </a>
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="form-group row mb-4">
            <label class="col-2 text-right">From Date</label>
            <div class="col-2 font-weight-bold">
              <span v-if="appData.policy.K_FromDate">
                {{
                  appData.policy.K_FromDate
                    | dateParse("YYYY-MM-DD")
                    | dateFormat("DD/MM/YYYY")
                }}
              </span>
            </div>

            <label class="col-2 text-right">Insurer</label>
            <div class="col-2 font-weight-bold">
              <a :href="insurerCrmLink" target="_blank">
                {{ appData.policy.K_Insurer.name }}
              </a>
            </div>

            <label class="col-2 text-right">Building Type</label>
            <div class="col-2 font-weight-bold">
               {{ appData.asset.K_BuildingType }}
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="form-group row mb-4">
            <label class="col-2 text-right">To Date</label>
            <div class="col-2 font-weight-bold">
              <span v-if="appData.policy.K_ToDate">
                {{
                  appData.policy.K_ToDate
                    | dateParse("YYYY-MM-DD")
                    | dateFormat("DD/MM/YYYY")
                }}
              </span>
            </div>

            <label class="col-2 text-right">Strata Company</label>
            <div class="col-2 font-weight-bold">
              <a :href="arCrmLink" target="_blank">
                {{ appData.strataCompany.Name }}
              </a>
            </div>

            <label class="col-2 text-right">Policy Owner</label>
            <div class="col-2 font-weight-bold">
              {{ appData.policy.Owner.name }}
            </div>
          </div>

          <!-- ROW 4 -->
          <div class="form-group row mb-4">
            <label class="col-2 text-right">Effective Date</label>
            <div class="col-2 font-weight-bold">
              <span v-if="appData.transaction.K_EffectiveDate">
                {{
                  appData.transaction.K_EffectiveDate
                  | dateParse("YYYY-MM-DD")
                  | dateFormat("DD/MM/YYYY")
                }}
              </span>
              <span v-else>
                  {{
                    appData.policy.K_FromDate
                      | dateParse("YYYY-MM-DD")
                      | dateFormat("DD/MM/YYYY")
                  }}
              </span>
            </div>

            <label class="col-2 text-right">Current Building Sum Insured</label>
            <div class="col-2 font-weight-bold">
              {{ appData.policy.bsi | currency("$", ",", 0, ".", "front", false) }}
            </div>

            <label class="col-2 text-right">Primary Strata Contact</label>
            <div class="col-2 font-weight-bold">
              <a :href="arCrmLink" target="_blank">
                {{ strataCompanyContactName }}
              </a>
            </div>
          </div>

          <!-- ROW 5 -->
          <div class="form-group row mb-4" v-if="isRenewalTransaction">
            <label class="col-2 text-right">Week Number</label>
            <div class="col-2 font-weight-bold">
              {{ appData.transaction.K_RenewalWeek }}
            </div>
            <label class="col-2 text-right">Indexation</label>
            <div class="col-2 font-weight-bold">
              {{ appData.policy.K_Indexation || 0 }} %
            </div>
          </div>
          <div class="form-group row mb-4">
            <label class="col-2 text-right">Annual Invoice Total</label>
            <div class="col-2 font-weight-bold">
              <span>
                {{ formattedTotalPremiumPayable }}
              </span>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";

export default {
  name: "PolicySummary",
  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
    };
  },
  computed: {
    isRenewalTransaction: function () {
      let transaction = _.get(this.appData, "transaction");
      if (transaction) {
        return transaction.K_Type === "Renewal";
      }
      return false;
    },

    formattedTotalPremiumPayable() {
      const value = this.appData.policy.K_TotalPremiumPayable || 0;
      return this.$options.filters.currency(value);
    },

    assetCrmLink: function () {
      let asset = _.get(this.appData, "asset");
      if (asset) {
        return `https://crm.zoho.com.au/crm/tab/${asset.K_CustomModuleName}/${asset.id}`;
      }
      return null;
    },

    policyCrmLink: function () {
      let policy = _.get(this.appData, "policy");
      if (policy) {
        return `https://crm.zoho.com.au/crm/tab/${policy.K_CustomModuleName}/${policy.id}`;
      }
      return null;
    },

    arCrmLink: function () {
      let strataCompany = _.get(this.appData, "strataCompany");
      if (strataCompany) {
        return `https://crm.zoho.com.au/crm/tab/${strataCompany.K_CustomModuleName}/${strataCompany.id}`;
      }
      return null;
    },

    strataCompanyContactName: function () {
      let strataCompanyPrimaryContact = _.get(
        this.appData,
        "strataCompany.K_PrimaryContact"
      );
      return strataCompanyPrimaryContact
        ? strataCompanyPrimaryContact.Name
        : null;
    },

    insurerCrmLink: function () {
      let insurer = _.get(this.appData, "policy.K_Insurer");
      if (insurer) {
        return `https://crm.zoho.com.au/crm/tab/${insurer.K_CustomModuleName}/${insurer.id}`;
      }
      return null;
    },

    assetAddressMapLink: function () {
      let address = _.get(this.appData, "asset.K_Address");
      if (address) {
        let suburb = _.get(this.appData, "asset.K_Suburb") || "";
        let state = _.get(this.appData, "asset.K_State") || "";
        let postcode = _.get(this.appData, "asset.K_Postcode") || "";
        address = `${address} ${suburb} ${state} ${postcode}`.trim();

        return `https://maps.google.com/?q=${address.replace(/ /g, "+")}`;
      }
      return null;
    },
  },
};
</script>
