<template>
  <div>
    <h4 class="font-weight-bold text-dark mt-10 mb-3">
      <i class="fas fa-file-alt fa-md text-primary"></i>
      <span class="ml-2" style="vertical-align: center">Endorsement Details</span>
    </h4>

    <div class="row form-group">
      <div class="col-2">
        <label class="col-form-label font-weight-bold font-size-lg">Endorsement Reason <span
            class="text-danger">*</span></label>
      </div>
      <div class="col-10">
        <ValidationProvider rules="required" name="appData.transaction.K_EndorsementReason" v-slot="{ errors }">
          <b-textarea class="form-control min-rows" v-model="appData.transaction.K_EndorsementReason" size="2000"
            :disabled="isReadOnly"></b-textarea>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="row form-group mt-10">
      <div class="col-2">
        <label class="col-form-label font-weight-bold">Endorsement Effective Date <span
            class="text-danger">*</span></label>
      </div>
      <div class="col-3">
        <ValidationProvider rules="required" name="appData.transaction.K_EffectiveDate" v-slot="{ errors }">
          <b-form-datepicker class="form-control" v-model="appData.transaction.K_EffectiveDate" :disabled="isReadOnly"
          :locale="'en-AU'"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }">
          </b-form-datepicker>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-2"></div>
      <div class="col-2">
        <label class="col-form-label font-weight-bold float-right">
          New Policy End Date <br /><span style="font-size: smaller; font-style: italic;">if applicable</span>
        </label>
      </div>
      <div class="col-3">
        <ValidationProvider rules="" name="appData.transaction.K_ToDate" v-slot="{ errors }">
          <b-form-datepicker class="form-control" v-model="appData.transaction.K_ToDate" :disabled="isReadOnly"
          :locale="'en-AU'"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }">
          </b-form-datepicker>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <declaration-form :formData="appData.declarationData.sumInsuredData" :configuration="sumsDataTableConfiguration"
      :is-read-only="isReadOnly" @delete-item="deleteItem" />

    <declaration-form :formData="appData.declarationData.excessData" :configuration="excessDataTableConfiguration"
      :is-read-only="isReadOnly" @delete-item="deleteItem" />

    <building-properties :form-data="appData.declarationData.assetPropertiesData"
      :configuration="buildingPropertiesConfiguration" :is-read-only="isReadOnly" @delete-item="deleteItem" />

  </div>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}
</style>

<script>
import DeclarationForm from "../../../common/DeclarationForm.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";

export default {
  name: "EndorsementDetails",
  components: {
    BuildingProperties,
    DeclarationForm,
  },
  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    sumsDataTableConfiguration: function () {
      return {
        title: "Sums Insured",
        currentTitle: "Current Value",
        renewalTitle: "New Value",
        showCurrentValue: true,
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
      };
    },

    excessDataTableConfiguration: function () {
      return {
        title: "Excesses",
        currentTitle: "Current Value",
        renewalTitle: "New Value",
        showCurrentValue: true,
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
      };
    },

    buildingPropertiesConfiguration: function () {
      return {
        title: "Building Properties",
        currentValueTitle: "Current Value",
        newValueTitle: "New Value",
        showCurrentValue: true,
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
      };
    },
  },
  methods: {
    deleteItem: function (item, formData) {
      let index = formData.findIndex(dataItem => dataItem === item);
      if (index !== -1) {
        formData.splice(index, 1);
      }
    },
  }
};
</script>
