var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row form-group"},[_vm._m(1),_c('div',{staticClass:"col-10"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"appData.transaction.K_EndorsementReason"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-textarea',{staticClass:"form-control min-rows",attrs:{"size":"2000","disabled":_vm.isReadOnly},model:{value:(_vm.appData.transaction.K_EndorsementReason),callback:function ($$v) {_vm.$set(_vm.appData.transaction, "K_EndorsementReason", $$v)},expression:"appData.transaction.K_EndorsementReason"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row form-group mt-10"},[_vm._m(2),_c('div',{staticClass:"col-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"appData.transaction.K_EffectiveDate"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"locale":'en-AU',"date-format-options":{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }},model:{value:(_vm.appData.transaction.K_EffectiveDate),callback:function ($$v) {_vm.$set(_vm.appData.transaction, "K_EffectiveDate", $$v)},expression:"appData.transaction.K_EffectiveDate"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-2"}),_vm._m(3),_c('div',{staticClass:"col-3"},[_c('ValidationProvider',{attrs:{"rules":"","name":"appData.transaction.K_ToDate"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"locale":'en-AU',"date-format-options":{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }},model:{value:(_vm.appData.transaction.K_ToDate),callback:function ($$v) {_vm.$set(_vm.appData.transaction, "K_ToDate", $$v)},expression:"appData.transaction.K_ToDate"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('declaration-form',{attrs:{"formData":_vm.appData.declarationData.sumInsuredData,"configuration":_vm.sumsDataTableConfiguration,"is-read-only":_vm.isReadOnly},on:{"delete-item":_vm.deleteItem}}),_c('declaration-form',{attrs:{"formData":_vm.appData.declarationData.excessData,"configuration":_vm.excessDataTableConfiguration,"is-read-only":_vm.isReadOnly},on:{"delete-item":_vm.deleteItem}}),_c('building-properties',{attrs:{"form-data":_vm.appData.declarationData.assetPropertiesData,"configuration":_vm.buildingPropertiesConfiguration,"is-read-only":_vm.isReadOnly},on:{"delete-item":_vm.deleteItem}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"font-weight-bold text-dark mt-10 mb-3"},[_c('i',{staticClass:"fas fa-file-alt fa-md text-primary"}),_c('span',{staticClass:"ml-2",staticStyle:{"vertical-align":"center"}},[_vm._v("Endorsement Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label font-weight-bold font-size-lg"},[_vm._v("Endorsement Reason "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label font-weight-bold"},[_vm._v("Endorsement Effective Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label font-weight-bold float-right"},[_vm._v(" New Policy End Date "),_c('br'),_c('span',{staticStyle:{"font-size":"smaller","font-style":"italic"}},[_vm._v("if applicable")])])])
}]

export { render, staticRenderFns }