<template>
  <ValidationObserver ref="endorsementWizard">
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-3"
          id="endorsement_wizard"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                v-for="(wizardPage, index) in wizardPages"
                :key="index"
                class="wizard-step"
                style="cursor: default"
                data-wizard-type="step"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span>{{ index + 1 }}</span
                    >{{ wizardPage.title }}
                  </h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body-->
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div
                class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10"
              >
                <div class="col-xl-12 col-xxl-9">
                  <!--begin: Wizard Form-->
                  <div class="form" id="endorsement_wizard_form">
                    <b-alert
                      v-if="wizardAppData && isWizardReadOnly"
                      variant="secondary"
                      show
                    >
                      <div class="text-danger">
                        <i class="fas fa-lock fa-md"></i>
                          <span class="ml-2" style="vertical-align: center"
                          > This endorsement is already finalised and cannot be
                          modified.</span
                        >
                      </div>
                    </b-alert>

                    <!-- ERROR ALERTS -->
                    <b-alert v-if="errorAlert" variant="warning" show>
                      <div class="text-dark" v-html="errorAlert"></div>
                    </b-alert>

                    <!--begin: Wizard Page 1-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <endorsement-page
                          :app-data="wizardAppData"
                          :is-read-only="isWizardReadOnly"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          "
                          @handleError="handleError"
                        />
                      </div>
                    </div>
                    <!--end: Wizard Page 1-->

                    <!--begin: Wizard Page 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <quote-page
                          :app-data="wizardAppData"
                          :is-read-only="isWizardReadOnly"
                          @updateWizardAppDataString="updateWizardAppDataString"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          "
                          @saveQuoteDetails="saveQuoteDetails"
                          @handleError="handleError"
                        />
                      </div>
                    </div>
                    <!--end: Wizard Page 2-->

                    <!--begin: Wizard Page 3-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <finalisation-page
                          :app-data="wizardAppData"
                          :is-read-only="isWizardReadOnly"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          "
                          @handleError="handleError"
                        />
                      </div>
                    </div>
                    <!--end: Wizard Page 3-->

                    <!--begin: Wizard Page 4-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <invoice-page
                          :app-data="wizardAppData"
                          :is-read-only="isWizardReadOnly"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          "
                          @handleError="handleError"
                        />
                      </div>
                    </div>
                    <!--end: Wizard Page 4-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        @click.prevent="goToPreviousPage"
                        v-show="showBackButton"
                      >
                        Previous Step
                      </button>
                      <button
                        class="btn btn-outline-danger font-weight-bold text-uppercase px-9 py-4"
                        v-show="wizardAppData && isWizardReadOnly"
                        @click.prevent="openReopenTransactionDialog"
                      >
                        Reopen Transaction
                      </button>
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        v-show="showNextStepButton"
                        @click.prevent="goToNextPage"
                      >
                        Next Step
                      </button>
                      <button
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        v-show="showFinaliseButton"
                        @click.prevent="finaliseTransaction"
                      >
                        Finalise Transaction
                      </button>
                    </div>
                    <!--end: Wizard Actions -->
                  </div>
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
        <!--end: Wizard-->

        <b-modal
          ref="reopenTransactionDialog"
          size="lg"
          no-close-on-backdrop
          title="Reopen Transaction"
          @ok="reopenTransaction"
          @hidden="reopenReason = null"
        >
          <ValidationObserver ref="indemnityStatusData">
            <div class="form-group row">
              <label class="col-4 col-form-label text-right"
                >Reason for reopening the endorsement<span class="text-danger"
                  >*</span
                ></label
              >
              <div class="col-8">
                <ValidationProvider
                  rules="required|max:2000"
                  name="reopenReason"
                  v-slot="{ errors }"
                >
                  <b-textarea
                    class="form-control min-rows"
                    v-model="reopenReason"
                    trim
                  ></b-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </ValidationObserver>
        </b-modal>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}

.switch-radio .v-input--selection-controls__ripple {
  margin: 2px 0;
  height: 24px;
  width: 40px;
  border-radius: 12px;
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.switch-radio .v-input--selection-controls__ripple:before {
  border: 2px solid #95979d;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: unset;
}

.switch-radio .v-input--selection-controls__ripple:after {
  content: "\f108";
  height: 20px;
  width: 20px;
  top: 2px;
  left: 0px;
  bottom: 2px;
  margin-left: 2px;
  font-size: 0.55em;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  border-radius: 50%;
  background: #ebedf3;
  transition: all 0.5s;
  font-family: ki;
  color: #ebedf3;
  line-height: 2;
}

.switch-radio input:empty ~ span:before {
  border: 2px solid #ebedf3;
  background-color: transparent;
  transform: unset;
}

.switch-radio input:checked ~ .v-input--selection-controls__ripple:after {
  left: 16px;
  background: #3699ff;
}

.switch-radio .v-input--selection-controls__input {
  width: 50px;
}

.switch-radio .v-input--selection-controls__input i {
  display: none;
}

.switch-radio input:checked ~ .v-input--selection-controls__ripple:before {
  border-color: #3699ff;
  opacity: 1;
  background-color: transparent;
}

.switch-radio
  .v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background-color: transparent;
  transform: unset;
}

.switch-radio .v-ripple__container {
  display: none;
}

.switch-radio label {
  margin-bottom: 0;
}
</style>

<script>
import _ from "lodash";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import EndorsementPage from "./pages/1-Endorsement/EndorsementPage.vue";
import QuotePage from "./pages/2-Quote/QuotePage.vue";
import FinalisationPage from "./pages/3-Finalisation/FinalisationPage.vue";
import InvoicePage from "./pages/4-Invoice/InvoicePage.vue";
import Swal from "sweetalert2";

export default {
  name: "EndorsementWizard",
  components: {
    EndorsementPage,
    QuotePage,
    FinalisationPage,
    InvoicePage,
  },
  data() {
    return {
      wizardPages: [
        {
          id: "wizardPage1",
          title: "Endorsement",
          subtitle: "Endorsement",
        },
        {
          id: "wizardPage2",
          title: "Quote",
          subtitle: "Review & Update Declaration",
        },
        {
          id: "wizardPage3",
          title: "Finalisation",
          subtitle: "Finalisation",
        },
        {
          id: "wizardPage4",
          title: "Invoice",
          subtitle: "Invoice",
        },
      ],

      userId: null,
      wizardObj: null,
      errorAlert: null,
      wizardAppData: null,
      wizardAppDataString: null,
      reopenReason: null,
    };
  },

  mounted() {
    // Show page loading
    this.startPageLoading();

    // Extract information from URL params
    let queryParams = this.$route.query;
    if (
      !queryParams.userId ||
      !queryParams.transactionId ||
      !queryParams.token
    ) {
      this.handleError("Unauthorised access!");
      return;
    }

    this.userId = queryParams.userId;

    // Initialise EndorsementDataService with the token
    this.$endorsementDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    // Initialise CommonDataService with the token
    this.$commonDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    // Initialise the UI wizard
    this.initWizard();

    // Get Application Data
    this.getWizardAppData();
  },

  computed: {
    isWizardReadOnly: function () {
      let txnStatus = _.get(this.wizardAppData, "transaction.K_Status");
      return txnStatus === "Completed";
    },

    isLastPage: function () {
      return this.wizardObj && this.wizardObj.isLastStep();
    },

    isQuotePage: function () {
      if (!this.wizardObj) {
        return false;
      }
      return this.wizardObj.getStep() === 2;
    },

    isFinalisationPage: function () {
      if (!this.wizardObj) {
        return false;
      }
      return this.wizardObj.getStep() === 3;
    },

    showNextStepButton: function () {
      if (!this.wizardAppData) {
        return false;
      } else if (this.isLastPage) {
        return false;
      } else if (this.showFinaliseButton) {
        return false;
      } else if (
        this.isFinalisationPage &&
        this.wizardAppData.statusFlags.quoteDeclinedByInsurer.value
      ) {
        return false;
      }
      return true;
    },

    showFinaliseButton: function () {
      if (!this.wizardAppData || this.isWizardReadOnly) {
        return false;
      } else if (
        this.isLastPage &&
        this.wizardAppData.statusFlags.cocSent.value === true
      ) {
        return true;
      } else if (
        this.isFinalisationPage &&
        this.wizardAppData.statusFlags.quoteAcceptedByClient.value ===
          "notRequired"
      ) {
        return true;
      } else if (
        this.isFinalisationPage &&
        this.wizardAppData.statusFlags.declinatureSentToClient.value
      ) {
        return true;
      }
      return false;
    },

    showBackButton: function () {
      if (!this.wizardObj) {
        return false;
      }
      if (this.wizardObj.getStep() === 1) {
        return false;
      }
      return true;
    },
  },

  methods: {
    initWizard: function () {
      // Initialize form wizard
      const wizard = new KTWizard("endorsement_wizard", {
        startStep: 1, // Initial active step number
        clickableSteps: false, // Do not allow step clicking
      });

      this.wizardObj = wizard;

      // Before-Change event
      wizard.on("change", (/*wizardObj*/) => {
        this.clearError();
        if (this.wizardAppData) {
          // Save app-data to server
          this.saveWizardAppData();
        }
      });

      // Page-Changed event - Scroll to top of the page
      wizard.on("changed", (wizardObj) => {
        // Save current page number to server
        let currentStep = wizardObj.getStep();
        this.saveWizardCurrentPage(currentStep);

        setTimeout(function () {
          KTUtil.scrollTop();
        }, 500);
      });
    },

    isPageValid: async function (pageNumber) {
      let isValid = false;
      if (!this.$refs || !this.$refs.endorsementWizard || !this.wizardAppData) {
        return isValid;
      }

      let page = this.wizardPages[pageNumber - 1];
      if (!page) {
        console.log(`Invalid page number ${pageNumber}`);
        return isValid;
      }

      let wizardPageId = page.id;
      let isDevelopment = process.env.NODE_ENV === "development";

      let childrenPages = this.$refs.endorsementWizard.$children;
      if (Array.isArray(childrenPages)) {
        let currentPageComponent = _.find(childrenPages, (child) => {
          return child.$el && child.$el.id === wizardPageId;
        });
        if (!currentPageComponent) {
          if (isDevelopment) {
            console.log(
              `Could not find currentPageComponent for id ${wizardPageId}`
            );
          }
        } else {
          let currentPageRef = currentPageComponent.$refs[wizardPageId];
          if (!currentPageRef) {
            if (isDevelopment) {
              console.log(
                `Could not find currentPageRef for id ${wizardPageId}`
              );
            }
          } else {
            isValid = await currentPageRef.validate();
            if (!isValid && isDevelopment) {
              console.log(currentPageRef.errors);
            }
          }
        }
      }
      return isValid;
    },

    goToPreviousPage: async function () {
      if (
        _.get(this.wizardAppData, "statusFlags.quoteNotRequired.value") &&
        this.wizardObj.currentStep === 4
      ) {
        // skip page 3, as this is not applicable
        this.wizardObj.goTo(this.wizardObj.currentStep - 2);
      } else {
        this.wizardObj.goTo(this.wizardObj.currentStep - 1);
      }
    },

    goToNextPage: async function () {
      this.clearError();
      let isNavAllowed = true;

      if (!this.isWizardReadOnly) {
        // Validate the current page
        isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
      }

      if (isNavAllowed) {
        // Save app-data to server
        this.saveWizardAppData();
        // Go to next page
        if (
          _.get(this.wizardAppData, "statusFlags.quoteNotRequired.value") &&
          this.wizardObj.currentStep === 2
        ) {
          // skip page 3, as this is not applicable
          this.wizardObj.goNext();
          this.wizardObj.goNext();
        } else {
          this.wizardObj.goNext();
        }
      }
    },

    getWizardAppData: function () {
      // Show page loading
      this.startPageLoading();
      this.$endorsementDataService
        .getWizardAppData()
        .then(({ data }) => {
          this.wizardAppData = data.result;
          this.wizardAppDataString = stringify(this.wizardAppData);

          // Set wizard title and subtitle
          this.setHeaderInformation();

          // Set starting step for the wizard
          let startStep = 1;
          if (this.isWizardReadOnly) {
            // If transaction is already completed, directly start the wizard on last page.
            startStep = this.wizardPages.length;
          } else if (this.wizardAppData.transaction) {
            // Check if the user was previously on a particular wizard page
            startStep = this.wizardAppData.transaction.K_WizardCurrentPage || 1;
          }

          // Stop page loading
          this.stopPageLoading();

          if (startStep !== 1) {
            setTimeout(() => {
              this.wizardObj.goTo(startStep);
            }, 0);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setHeaderInformation: function () {
      if (this.wizardAppData) {
        // Set wizard title and subtitle in global store so that it can be shown on the header
        this.$store.commit(
          "setWizardTitle",
          this.wizardAppData.transaction.Name
        );
        this.$store.commit(
          "setWizardSubtitle",
          this.wizardAppData.asset.K_PlanNumber
        );

        // Update transaction status on the header
        this.updateUiTransactionStatus();

        // Update Box link on the sticky-toolbar
        this.setTransactionBoxLink();
      }
    },

    updateWizardAppDataString: function () {
      this.wizardAppDataString = stringify(this.wizardAppData);
    },

    updateServerTransactionStatus: function (flagName) {
      let flagValue = this.wizardAppData.statusFlags[flagName].value;
      this.$endorsementDataService
        .updateTransactionStatus(flagName, flagValue)
        .then(({ data }) => {
          let updatedStatus = data.result;
          if (updatedStatus) {
            // Update UI transaction status
            let txn = this.wizardAppData.transaction;
            txn.K_Status = updatedStatus.K_Status;
            txn.K_StatusDetails = updatedStatus.K_StatusDetails;
            txn.K_NextStep = updatedStatus.K_NextStep;

            for (let key of Object.keys(updatedStatus.statusFlags)) {
              let currentValue = this.wizardAppData.statusFlags[key].value;
              let newValue = updatedStatus.statusFlags[key].value;
              if (currentValue !== newValue)
                this.$set(
                  this.wizardAppData.statusFlags[key],
                  "value",
                  newValue
                );
            }
          }
          // Update UI
          this.updateUiTransactionStatus();

          // Update wizardAppDataString
          // this.wizardAppDataString = stringify(this.wizardAppData);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    saveQuoteDetails: function (quote) {
      this.clearError();
      this.startPageLoading();
      this.$endorsementDataService
        .saveQuoteDetails(quote)
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateUiTransactionStatus: function () {
      if (this.wizardAppData && this.wizardAppData.transaction) {
        let txn = this.wizardAppData.transaction;
        let txnStatus = {
          status: txn.K_Status,
          statusDetails: txn.K_StatusDetails,
          nextStep: txn.K_NextStep,
        };
        // Set transaction status in global store so that it can be shown on the header
        this.$store.commit("setTransactionStatus", txnStatus);
      }
    },

    setTransactionBoxLink: function () {
      let transactionBoxFolderId = _.get(
        this.wizardAppData,
        "transaction.K_TransactionBoxFolderId"
      );
      if (transactionBoxFolderId) {
        let txnBoxFolderLink = `https://collectiveib.app.box.com/folder/${transactionBoxFolderId}`;

        // Set transaction status in global store so that it can be shown on the header
        this.$store.commit("setTransactionBoxFolderLink", txnBoxFolderLink);
      }
    },

    saveWizardAppData: function (noSpinner) {
      if (this.isWizardReadOnly) {
        return;
      }
      // Save app-data to the server (only if it has changed)
      let currentAppDataString = stringify(this.wizardAppData);
      if (currentAppDataString !== this.wizardAppDataString) {
        if (!noSpinner) {
          this.startPageLoading();
        }

        this.$endorsementDataService
          .saveWizardAppData(this.wizardAppData)
          .then(({ data }) => {
            this.wizardAppData = data.result;
            this.wizardAppDataString = stringify(this.wizardAppData);

            // Update transaction status & name on the header
            this.setHeaderInformation();

            if (!noSpinner) {
              this.stopPageLoading();
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },

    saveWizardCurrentPage: function (pageNumber) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.$commonDataService
        .saveWizardCurrentPage(pageNumber)
        .catch((error) => {
          this.handleError(error);
        });
    },

    refreshWizardAppData: function () {
      this.$endorsementDataService
        .getWizardAppData()
        .then(({ data }) => {
          this.wizardAppData = data.result;
          this.wizardAppDataString = stringify(this.wizardAppData);

          // Update transaction status & name on the header
          this.setHeaderInformation();

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    finaliseTransaction: function () {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();
      this.startPageLoading();

      // Save wizard-app-data to server
      this.$endorsementDataService
        .saveWizardAppData(this.wizardAppData)
        .then((/*{ data }*/) => {
          // Finalise transaction
          this.$endorsementDataService
            .finaliseTransaction()
            .then((/*{ data }*/) => {
              // Refresh wizard-app-data
              this.refreshWizardAppData();

              this.$nextTick(() => {
                Swal.fire({
                  title: "Endorsement Finalised",
                  html: `<p class="text-justify">This Endorsement transaction has been finalised.</p><br/>
               <span class="font-weight-bold">Please close this browser window.</span>`,
                  icon: "success",
                  allowOutsideClick: false,
                  allowEnterKey: false,
                  allowEscapeKey: false,
                  showCancelButton: false,
                  showCloseButton: false,
                });
              });
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    openReopenTransactionDialog: function () {
      this.$refs["reopenTransactionDialog"].show();
    },

    reopenTransaction: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.reopenReason) {
        this.startPageLoading();
        this.$endorsementDataService
          .reopenTransaction(this.reopenReason)
          .then((/*{ data }*/) => {
            this.$refs["reopenTransactionDialog"].hide();
            // Refresh wizard-app-data
            this.refreshWizardAppData();
          })
          .catch((error) => {
            this.handleError(error);
            this.reopenReason = null;
            this.$refs["reopenTransactionDialog"].hide();
          });
      }
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function (errorObj) {
      this.stopPageLoading();
      let errorMsg = null;
      const uiErrorPrefix = "UI_ERROR:";

      if (errorObj) {
        if (
          typeof errorObj === "string" &&
          errorObj.startsWith(uiErrorPrefix)
        ) {
          errorMsg = errorObj.substring(uiErrorPrefix.length, errorObj.length);
        } else {
          let responseStatus = _.get(errorObj, "response.status");
          if (
            responseStatus === 401 ||
            (errorObj.message && errorObj.message.includes("status code 401"))
          ) {
            errorMsg =
              "This session is invalid or expired. Please close this window.";
          } else {
            let responseResult = _.get(errorObj, "response.data.result");
            if (_.isString(responseResult)) {
              if (responseResult.startsWith(uiErrorPrefix)) {
                errorMsg = responseResult.substring(
                  uiErrorPrefix.length,
                  responseResult.length
                );
              }
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }
      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function () {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function () {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
  },
};
</script>
