<template>
    <ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
        <div v-if="appData">
            <policy-alert :app-data="appData" :is-read-only="true" @alertSaved="$emit('alertSaved', $event)"
                @handleError="$emit('handleError', $event)" />

            <policy-summary :app-data="appData" />

            <instructions :instructions-text="instructions" />

            <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                <declaration-form :formData="appData.declarationData.sumInsuredData"
                    :configuration="sumsDataTableConfiguration" :is-read-only="true" />

                <declaration-form :formData="appData.declarationData.excessData"
                    :configuration="excessDataTableConfiguration" :is-read-only="true" />

                <building-properties :form-data="appData.declarationData.assetPropertiesData"
                    :configuration="buildingPropertiesConfiguration" :is-read-only="true" />

                <div class="form-group mt-10">
                    <h4 class="fs-2x fw-bolder mb-0 text-center py-4">
                        Special Invoicing Instructions
                    </h4>
                    <textarea v-model="appData.transaction.K_SpecialInvoicingInstructions" class="form-control min-rows"
                        disabled></textarea>
                </div>

                <b-card border-variant="" body-class="px-2 py-2" class="mt-5 mb-5">
                    <h4 class="fs-2x fw-bolder mb-0 text-center py-4">
                        Financial Details
                    </h4>
                    <div v-if="appData.transaction">
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right">Base Premium</label>
                            <div class="col-2">
                                <currency-input class="form-control" v-model="appData.transaction.K_BasePremium" disabled />
                            </div>
                            <label class="col-2 col-form-label text-right">ESL Levies</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="eslLevies" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_EslLevies"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="gst" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_Gst" disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right">Stamp Duty</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="stampDuty" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_StampDuty"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee (ex. GST)</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFee" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_AdminFee"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFeeGst" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_AdminFeeGst"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total Insurer Payable</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="totalInsurerPayable" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.transaction.K_TotalInsurerPayable"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <fees-and-commission :quote="appData.transaction" :is-read-only="true"></fees-and-commission>

                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total Premium Payable</label>
                            <div class="col-2">
                                <currency-input class="form-control" v-model="appData.transaction.K_TotalPremiumPayable"
                                    disabled />
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card border-variant="secondary" body-class="px-4 py-4" class="mt-10">
                    <div class="form-group row">
                        <label class="col-2 col-form-label text-right font-weight-bold">Enter Invoice Number<span
                                class="text-danger">*</span></label>
                        <div class="col-2">
                            <ValidationProvider rules="required|max:255" name="appData.transaction.K_InvoiceNumber"
                                v-slot="{ errors }">
                                <input type="text" class="form-control" v-model="appData.transaction.K_InvoiceNumber"
                                    maxlength="255" :disabled="isReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                                <div class="text-danger" v-if="invoiceNumberMissing">
                                    Please enter the invoice number.
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-8">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.insightDataUpdated" v-slot="{ errors }">
                                <div class="row">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                        <label>
                                            <input type="checkbox" v-model="appData.statusFlags.insightDataUpdated.value"
                                                :disabled="isReadOnly || !appData.transaction.K_InvoiceNumber
                                                    " @change="checkInvoiceNumber" />
                                            <span></span>
                                        </label>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that the above information has been entered into
                                        INSIGHT.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </b-card>

                <div v-if="appData.statusFlags.insightDataUpdated.value === true">
                    <b-card border-variant="secondary" body-class="px-4 py-4" class="mt-10">
                        <div class="form-group row">
                            <div class="col-2"></div>
                            <div class="col-2">
                                <button type="button" class="btn btn-primary" @click.prevent="getInvoiceEmailTemplate"
                                    :disabled="isReadOnly">
                                    <i class="fa fa-envelope"></i>
                                    Send Invoice
                                </button>
                            </div>
                            <div class="col-8">
                                <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                    name="appData.statusFlags.invoiceSent" v-slot="{ errors }">
                                    <div class="row">
                                        <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                            <label>
                                                <input type="checkbox" v-model="appData.statusFlags.invoiceSent.value"
                                                    :disabled="isReadOnly" @change="
                                                        $emit(
                                                            'updateServerTransactionStatus',
                                                            'invoiceSent'
                                                        )
                                                        " />
                                                <span></span>
                                            </label>
                                        </span>
                                        <label class="col-form-label font-size-h5 ml-2">
                                            I confirm that I have sent the invoice to the client.
                                        </label>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </b-card>

                    <div v-if="appData.statusFlags.invoiceSent.value === true">
                        <b-card border-variant="secondary" body-class="px-4 py-4" class="mt-10">
                            <div class="form-group row">
                                <div class="col-2"></div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-primary" @click.prevent="getClosingEmailTemplate"
                                        :disabled="isReadOnly">
                                        <i class="fa fa-envelope"></i>
                                        Send Closing
                                    </button>
                                </div>
                                <div class="col-8">
                                    <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                        name="appData.statusFlags.closingSent" v-slot="{ errors }">
                                        <div class="row">
                                            <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                                <label>
                                                    <input type="checkbox" v-model="appData.statusFlags.closingSent.value"
                                                        :disabled="isReadOnly" @change="
                                                            $emit(
                                                                'updateServerTransactionStatus',
                                                                'closingSent'
                                                            )
                                                            " />
                                                    <span></span>
                                                </label>
                                            </span>
                                            <label class="col-form-label font-size-h5 ml-2">
                                                I confirm that I have sent the Closing to the insurer.
                                            </label>
                                        </div>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </b-card>

                        <div v-if="appData.statusFlags.closingSent.value === true">
                            <b-card border-variant="secondary" body-class="px-4 py-4" class="mt-10">
                                <div class="form-group row">
                                    <div class="col-2"></div>
                                    <div class="col-2">
                                        <button type="button" class="btn btn-primary" @click.prevent="getCocEmailTemplate"
                                            :disabled="isReadOnly" style="min-width: 150px">
                                            <i class="fa fa-envelope"></i>
                                            Send CoC
                                        </button>
                                    </div>
                                    <div class="col-8">
                                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                            name="appData.statusFlags.cocSent" v-slot="{ errors }">
                                            <div class="row">
                                                <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                                    <label>
                                                        <input type="checkbox" v-model="appData.statusFlags.cocSent.value"
                                                            :disabled="isReadOnly" @change="
                                                                $emit(
                                                                    'updateServerTransactionStatus',
                                                                    'cocSent'
                                                                )
                                                                " />
                                                        <span></span>
                                                    </label>
                                                </span>
                                                <label class="col-form-label font-size-h5 ml-2">
                                                    I confirm that I have sent the Certificate of Currency
                                                    to the client.
                                                </label>
                                            </div>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-card>

                            <div v-if="appData.statusFlags.cocSent.value === true && !isReadOnly"
                                class="row justify-content-center mt-5 mb-5">
                                <h4>
                                    Please click the 'Finalise Transaction' button below to
                                    finalise the renewal.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import PolicySummary from "../../../common/PolicySummary.vue";
import PolicyAlert from "../../../common/PolicyAlert.vue";
import Instructions from "../../../common/Instructions.vue";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import FeesAndCommission from "../../../common/FeesAndCommission.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";

export default {
    name: "InvoicePage",
    components: {
        BuildingProperties,
        PolicySummary,
        PolicyAlert,
        Instructions,
        DeclarationForm,
        FeesAndCommission,
    },
    props: {
        appData: Object,
        isReadOnly: Boolean,
    },
    data() {
        return {
            invoiceNumberMissing: false,
        };
    },

    computed: {
        instructions: function () {
            return `Please enter the information in INSIGHT, and then send the documents to the client.`;
        },

        currencyConfig: function () {
            return {
                allowNegative: false,
                distractionFree: false,
                precision: 2,
            };
        },

        sumsDataTableConfiguration: function () {
            return {
                title: "Sums Insured",
                currentTitle: "Current Value",
                renewalTitle: "New Value",
                showCurrentValue: false,
            };
        },

        excessDataTableConfiguration: function () {
            return {
                title: "Excesses",
                currentTitle: "Current Value",
                renewalTitle: "New Value",
                showCurrentValue: false,
            };
        },

        buildingPropertiesConfiguration: function () {
            return {
                title: "Building Properties",
                showCurrentValue: true,
                showNewValue: true,
                currentValueTitle: "Current Value",
                newValueTitle: "New Value",
            };
        },
    },

    methods: {
        checkInvoiceNumber: function () {
            this.invoiceNumberMissing = false;
            if (this.appData.statusFlags.insightDataUpdated.value) {
                if (!this.appData.transaction.K_InvoiceNumber) {
                    this.invoiceNumberMissing = true;
                    this.$set(
                        this.appData.statusFlags.insightDataUpdated,
                        "value",
                        false
                    );
                    return;
                }
            }
            this.$emit("updateServerTransactionStatus", "insightDataUpdated");
        },

        getInvoiceEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_CLIENT_INVOICE")
                .then(({ data }) => {
                    this.launchEmailTemplate(data.result);
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getClosingEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_INSURER_CLOSING")
                .then(({ data }) => {
                    this.launchEmailTemplate(data.result);
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getCocEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_CLIENT_COC")
                .then(({ data }) => {
                    this.launchEmailTemplate(data.result);
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        launchEmailTemplate: function (emailTemplate) {
            if (!emailTemplate) {
                return;
            }
            const url = window.URL.createObjectURL(new Blob([emailTemplate]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `cib-email-${new Date().getTime()}.eml`);
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>
