<template>
    <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
        <div>
            <div v-if="appData">
                <policy-alert :app-data="appData" :is-read-only="isReadOnly" @alertSaved="$emit('alertSaved', $event)" />

                <policy-summary :app-data="appData" />

                <instructions :instructions-text="instructions" />

                <div v-if="appData.statusFlags.quoteDeclinedByInsurer.value" class="mt-10">
                    <button type="button" class="btn btn-primary"
                        @click.prevent="getClientEndorsementDeclinatureEmailTemplate" :disabled="isReadOnly">
                        <i class="fa fa-envelope"></i>
                        Send Declinature to Client
                    </button>

                    <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                        name="appData.statusFlags.declinatureSentToClient" v-slot="{ errors }">
                        <div class="row mt-2">
                            <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                <label>
                                    <input type="checkbox" :disabled="isReadOnly"
                                        v-model="appData.statusFlags.declinatureSentToClient.value" @change="
                                            $emit(
                                                'updateServerTransactionStatus',
                                                'declinatureSentToClient'
                                            )
                                            " />
                                    <span></span>
                                </label>
                            </span>
                            <label class="col-form-label font-size-h5 ml-2">
                                I confirm that I have sent the Insurer endorsement declinature
                                to the client.
                            </label>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div v-if="!appData.statusFlags.quoteDeclinedByInsurer.value &&
                    appData.statusFlags.insurerRequestSent.value === 'done'
                    " class="mt-10">
                    <button type="button" class="btn btn-primary" @click.prevent="getClientEndorsementQuoteEmailTemplate"
                        :disabled="isReadOnly">
                        <i class="fa fa-envelope"></i>
                        Send Quote to Client
                    </button>

                    <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                        name="appData.statusFlags.quoteSentToClient" v-slot="{ errors }">
                        <div class="row mt-2">
                            <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                <label>
                                    <input type="checkbox" :disabled="isReadOnly"
                                        v-model="appData.statusFlags.quoteSentToClient.value" @change="
                                            $emit(
                                                'updateServerTransactionStatus',
                                                'quoteSentToClient'
                                            )
                                            " />
                                    <span></span>
                                </label>
                            </span>
                            <label class="col-form-label font-size-h5 ml-2">
                                I confirm that I have sent the endorsement quote to the client.
                            </label>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div v-if="appData.statusFlags.quoteSentToClient.value &&
                    appData.statusFlags.insurerRequestSent.value === 'done'
                    " class="mt-5 pt-5 border-top">
                    <h4>Accept or Reject Quote</h4>

                    <ValidationProvider rules="required" name="appData.statusFlags.quoteAcceptedByClient"
                        v-slot="{ errors }">
                        <v-radio-group v-model="appData.statusFlags.quoteAcceptedByClient.value" @change="
                            $emit('updateServerTransactionStatus', 'quoteAcceptedByClient')
                            ">
                            <v-radio label="Accepted by Client" value="done" class="switch-radio"
                                :disabled="isReadOnly"></v-radio>
                            <v-radio label="Rejected by Client" value="notRequired" class="switch-radio"
                                :disabled="isReadOnly"></v-radio>
                        </v-radio-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <div v-if="appData.statusFlags.quoteAcceptedByClient.value === 'done'" class="pt-5 border-top">
                        <button type="button" class="btn btn-primary"
                            @click.prevent="getInsurerEndorsementConfirmationEmailTemplate">
                            <i class="fa fa-envelope"></i>
                            Send Confirmation to Insurer
                        </button>

                        <ValidationProvider rules="required" name="appData.statusFlags.insurerConfirmationSent"
                            v-slot="{ errors }">
                            <v-radio-group v-model="appData.statusFlags.insurerConfirmationSent.value" @change="
                                $emit(
                                    'updateServerTransactionStatus',
                                    'insurerConfirmationSent'
                                )
                                ">
                                <v-radio label="I confirm that I have sent the endorsement confirmation to the Insurer."
                                    value="done" class="switch-radio" :disabled="isReadOnly"></v-radio>
                                <!-- <v-radio
                  label="Not required"
                  value="notRequired"
                  class="switch-radio"
                  :disabled="isReadOnly"
                ></v-radio> -->
                            </v-radio-group>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="pt-5 border-top">
                            <button type="button" class="btn btn-primary"
                                @click.prevent="getClientEndorsementConfirmationEmailTemplate">
                                <i class="fa fa-envelope"></i>
                                Send Confirmation to Client
                            </button>

                            <ValidationProvider rules="required" name="appData.statusFlags.clientConfirmationSent"
                                v-slot="{ errors }">
                                <v-radio-group v-model="appData.statusFlags.clientConfirmationSent.value" @change="
                                    $emit(
                                        'updateServerTransactionStatus',
                                        'clientConfirmationSent'
                                    )
                                    ">
                                    <v-radio label="I confirm that I have sent the endorsement confirmation to the Client."
                                        value="done" class="switch-radio" :disabled="isReadOnly"></v-radio>
                                    <!-- <v-radio
                    label="Not required"
                    value="notRequired"
                    class="switch-radio"
                    :disabled="isReadOnly"
                  ></v-radio> -->
                                </v-radio-group>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="form-group mt-5 pt-5 border-top">
                            <label class="col-form-label font-weight-bold font-size-h6">
                                Please specify any special instructions for the off-shore
                                processing team
                            </label>
                            <b-textarea class="form-control min-rows"
                                v-model="appData.transaction.K_SpecialInvoicingInstructions" :disabled="isReadOnly"
                                size="2000"></b-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import PolicySummary from "../../../common/PolicySummary.vue";
import PolicyAlert from "../../../common/PolicyAlert.vue";
import Instructions from "../../../common/Instructions.vue";

export default {
    name: "FinalisationPage",

    components: {
        PolicySummary,
        PolicyAlert,
        Instructions,
    },

    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    computed: {
        instructions: function () {
            return (
                "Please send the endorsement quote to the client then accept (or reject) the quote. If accepted, send confirmation to the Insurer/Client." +
                '<br><br>If sending a request to the Insurer was not required, you can select <span style="color:navy;">Next Step</span> to move to the Invoice step.'
            );
        },
    },

    methods: {
        getClientEndorsementQuoteEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_CLIENT_QUOTE")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getClientEndorsementDeclinatureEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_CLIENT_DECLINED")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getInsurerEndorsementConfirmationEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_INSURER_CONFIRMATION")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getClientEndorsementConfirmationEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_CLIENT_CONFIRMATION")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },
    },

    data() {
        return {};
    },
};
</script>
