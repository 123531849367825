<template>
    <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
        <div>
            <div v-if="appData">
                <policy-alert :app-data="appData" :is-read-only="isReadOnly" @alertSaved="$emit('alertSaved', $event)" />

                <policy-summary :app-data="appData" />

                <instructions :instructions-text="instructions" />

                <h4 class="mt-5">Send Endorsement Request to Insurer</h4>
                <div class="form-group">
                    <label class="col-form-label font-weight-bold font-size-lg">
                        Endorsement Request Details for Insurer (to be included in email to
                        Insurer)
                    </label>
                    <b-textarea class="form-control min-rows" v-model="appData.transaction.K_SpecialInstructionsForInsurer"
                        size="2000" :disabled="isReadOnly">
                    </b-textarea>
                </div>

                <div class="form-group row mb-2 mt-2">
                    <label class="col-2 col-form-label">Endorsement Insurer Contact:</label>
                    <div class="col-4 font-weight-bold">
                        <ValidationProvider rules="" name="appData.transaction.uiEndorsementContact" v-slot="{ errors }">
                            <b-form-select v-model="appData.transaction.uiEndorsementContact" :disabled="isReadOnly">
                                <b-form-select-option v-for="contact in appData.insurerContacts" :key="contact.id"
                                    :value="contact">
                                    {{ contact.Name }} - {{ contact.Email }}
                                </b-form-select-option>
                            </b-form-select>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="mt-3" v-if="!isReadOnly">
                    <button type="button" class="btn btn-primary" v-if="!isReadOnly"
                        @click.prevent="getInsurerEndorsementEmailTemplate" :disabled="!appData.transaction.uiEndorsementContact || loadingEmailTemplate
                            ">
                        <i class="fa fa-envelope"></i>
                        Send Email to Insurer
                    </button>
                    <b-spinner class="ml-3 align-middle" variant="primary" v-if="loadingEmailTemplate"></b-spinner>
                </div>

                <ValidationProvider rules="required" name="appData.statusFlags.insurerRequestSent" v-slot="{ errors }">
                    <v-radio-group v-model="appData.statusFlags.insurerRequestSent.value"
                        @change="handleInsurerRequestSentChange">
                        <v-radio label="I confirm that I have sent the endorsement request to the Insurer." value="done"
                            class="switch-radio" :disabled="isReadOnly"></v-radio>
                        <v-radio label="Not required." value="notRequired" class="switch-radio"
                            :disabled="isReadOnly"></v-radio>
                    </v-radio-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <div v-if="appData.statusFlags.insurerRequestSent.value === 'done'" class="mt-5">
                    <h4 class="mt-5">Enter Insurer Quote Details</h4>
                    <button type="button" class="btn btn-primary mt-3" @click.prevent="openUpdateQuoteDialog"
                        :disabled="loadingQuoteDetails">
                        <i v-if="isReadOnly" class="fa-solid fa-eye"></i>
                        <i v-else class="fa-solid fa-pen"></i>
                        {{ isReadOnly ? "View" : "Update" }} Quote
                    </button>
                    <b-spinner class="ml-3 align-middle" variant="primary" v-if="loadingQuoteDetails"></b-spinner>
                    <b-modal ref="updateQuoteDialog" size="xl" ok-title="Save" scrollable no-close-on-esc
                        no-close-on-backdrop hide-header-close title="Update Insurer Quote" @ok="saveQuoteDetails">
                        <update-quote-dialog :quote="quoteBeingUpdated" :is-read-only="isReadOnly" />
                    </b-modal>

                    <!-- Hidden input to validate if quote has been updated -->
                    <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                        name="appData.statusFlags.quoteDetailsUpdated" v-slot="{ errors }">
                        <input type="hidden" v-model="appData.statusFlags.quoteDetailsUpdated.value" />
                        <div class="text-danger mt-1">
                            {{
                                errors[0]
                                ? "Quote details must be entered before proceeding."
                                : ""
                            }}
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import PolicyAlert from "../../../common/PolicyAlert.vue";
import PolicySummary from "../../../common/PolicySummary.vue";
import Instructions from "../../../common/Instructions.vue";
import UpdateQuoteDialog from "../../../common/UpdateQuoteDialog.vue";

export default {
    name: "QuotePage",

    components: {
        PolicyAlert,
        PolicySummary,
        Instructions,
        UpdateQuoteDialog,
    },

    data() {
        return {
            quoteBeingUpdated: {},
            loadingQuoteDetails: false,
            loadingEmailTemplate: false,
        };
    },

    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    computed: {
        instructions: function () {
            return "Please enter the required endorsement details below then send the request to the Insurer. Once the quote has been received back from the Insurer update the quote details.If no insurer quote is required, simply specify 'Not required' below then you will be able to finalise the endorsement without sending a quote/invoice to the client.";
        },
    },

    methods: {
        saveQuoteDetails: function () {
            this.$refs["updateQuoteDialog"].hide();
            if (this.isReadOnly) {
                return;
            }
            this.$emit("saveQuoteDetails", this.quoteBeingUpdated);
        },

        openUpdateQuoteDialog: function () {
            this.loadingQuoteDetails = true;

            // First, save data from the endorsement request details
            let enContact = this.appData.transaction.uiEndorsementContact;
            let txnData = {
                K_EndorsementInsurerContact: enContact?.Name,
                K_EndorsementInsurerContactEmail: enContact?.Email,
                K_SpecialInstructionsForInsurer:
                    this.appData.transaction.K_SpecialInstructionsForInsurer,
            };
            this.$endorsementDataService
                .updateTransactionData(txnData)
                .then((/*{ data }*/) => {
                    this.$emit("updateWizardAppDataString");
                })
                .catch((error) => {
                    this.loadingQuoteDetails = false;
                    this.$emit("handleError", error);
                });

            this.$endorsementDataService
                .getQuoteDetails(this.appData.quoteId)
                .then(({ data }) => {
                    this.quoteBeingUpdated = data.result;
                    this.$set(this.appData, "quoteId", this.quoteBeingUpdated.id);
                    this.$refs["updateQuoteDialog"].show();
                    this.loadingQuoteDetails = false;
                })
                .catch((error) => {
                    this.loadingQuoteDetails = false;
                    this.$emit("handleError", error);
                });
        },

        getInsurerEndorsementEmailTemplate: function () {
            let enContact = this.appData.transaction.uiEndorsementContact;
            if (!enContact || !enContact.Name) {
                this.$emit(
                    "handleError",
                    "UI_ERROR:Please Select the Endorsement Insurer Contact."
                );
                return;
            }
            this.loadingEmailTemplate = true;
            let txnData = {
                K_EndorsementInsurerContact: enContact.Name,
                K_EndorsementInsurerContactEmail: enContact.Email,
                K_SpecialInstructionsForInsurer:
                    this.appData.transaction.K_SpecialInstructionsForInsurer,
            };

            this.$endorsementDataService
                .updateTransactionData(txnData)
                .then((/*{ data }*/) => {
                    this.$emit("updateWizardAppDataString");

                    this.$commonDataService
                        .getEmailTemplate("ENDORSEMENT_INSURER_REQUEST")
                        .then(({ data }) => {
                            let emailTemplate = data.result;
                            const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute(
                                "download",
                                `cib-email-${new Date().getTime()}.eml`
                            );
                            document.body.appendChild(link);
                            link.click();
                            this.loadingEmailTemplate = false;
                        })
                        .catch((error) => {
                            this.$emit("handleError", error);
                            this.loadingEmailTemplate = false;
                        });
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                    this.loadingEmailTemplate = false;
                });
        },

        handleInsurerRequestSentChange: async function () {
            if (this.appData.statusFlags.insurerRequestSent.value === "notRequired") {
                this.$set(this.appData.statusFlags, "quoteNotRequired", {
                    value: true,
                });
                await this.$emit("updateServerTransactionStatus", "quoteNotRequired");
            } else if (this.appData.statusFlags.insurerRequestSent.value === "done") {
                this.$set(this.appData.statusFlags, "quoteNotRequired", {
                    value: false,
                });
                await this.$emit("updateServerTransactionStatus", "insurerRequestSent");
            }
        },
    },
};
</script>
